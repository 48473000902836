import React, { useState } from "react";

import { Switch } from "@material-ui/core";

import CartMaker from "~/components/Appointments/CartMaker/CartMaker";
import CartMakerV2 from "~/components/Appointments/CartMaker/CartMakerV2";
import PrivateRoute from "~/components/Authentication/PrivateRoute";

const CheckoutBuilder = (): React.ReactNode => {
  const [forSdk, setForSdk] = useState<boolean>(false);
  return (
    <PrivateRoute>
      <div className="flex justify-center items-center">
        Marketplace
        <Switch
          color="primary"
          checked={forSdk}
          onChange={() => setForSdk((oldValue) => !oldValue)}
        />
        SDK
      </div>
      {!forSdk && <CartMakerV2 />}
      {forSdk && <CartMaker forSdk />}
    </PrivateRoute>
  );
};

export default CheckoutBuilder;
